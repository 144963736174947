import React, { useContext, useEffect } from 'react';

import { PublicConfigsContext } from '../contexts/PublicConfigsContext';
import { useGetPublicConfigsQuery } from '../redux/services/publicConfigService';

interface LoadInitialPublicStateProps {
  children?: React.ReactNode;
}

// Type guard to check if error is FetchBaseQueryError
const isFetchBaseQueryError = (error: unknown): error is { status: number } => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'status' in error &&
    typeof (error as { status?: unknown }).status === 'number'
  );
};

const LoadInitialPublicState = (props: LoadInitialPublicStateProps) => {
  const {
    data: configsData,
    isSuccess: isConfigsSuccess,
    isError: isConfigsError,
    error: configsError,
  } = useGetPublicConfigsQuery();

  if (isConfigsError) {
    // If the current user information can't be loaded, redirect to an error page instead of letting
    // individual components fail.
    const currentErrorCode = isFetchBaseQueryError(configsError) ? configsError.status : 500;

    // Only allow codes: 403, 404, 422, 500, or 502. Default to 500 if not in this set.
    // Since pages are created in the public directory only for these codes
    const allowedErrorCodes = [403, 404, 422, 500, 502];
    const errorCode = allowedErrorCodes.includes(currentErrorCode) ? currentErrorCode : 500;

    window.location.href = `/${errorCode}.html`;
  }

  const { setConfigs } = useContext(PublicConfigsContext);

  useEffect(() => {
    if (isConfigsSuccess) {
      // In order to prevent a flash of partially rendered content we are initially hiding the the body (.invisible) until
      // react can load. Once we have successfully loaded the required data and rendered the page, we need to display
      // the body. Note: This is probably not perfect because we have multiple react apps and the first one will
      // show the page, but it is definitely an improvement.
      document.body.classList.remove('invisible');
      if (configsData) {
        setConfigs(configsData);
      }
    }
  }, [isConfigsSuccess, configsData, setConfigs]);

  if (isConfigsSuccess) {
    return <>{props.children}</>;
  } else {
    return <></>;
  }
};

export default LoadInitialPublicState;
